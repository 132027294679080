<template>
	<v-container class="pt-0">
		<div>
			<v-row>
				<v-col cols="12" lg="12" md="12" class="pr-0">
					<s-crud
						:config="config"
						:filter="filter"
                        add
                        @save="save($event)"
                        edit
						remove
						search-input
						title="Config. de Turnos"
						no-full
						height="auto"
					>
						<template v-slot:filter>
							<v-row justify="center" style="margin:auto">
								<v-col cols="12" lg="4" md="4" sm="12">
									<s-select-definition
										label="Nave"
										:def="1342"
										v-model="filter.TypeShip"
									></s-select-definition>
								</v-col>
							</v-row>
							
						</template>
						<template scope="props">
                            <v-container>
                                <v-row justify="center">

								<v-col cols="12" lg="2" md="4" sm="12">
									<s-select-definition
										label="Nave"
										:def="1342"
										v-model="props.item.TypeShip"
										@input="inputShip($event)"
									></s-select-definition>
								</v-col>


								<v-col cols="7" md="6" lg="3">
									<s-select-definition
										:def="def"
										:dgr="dgr"
										label="Area"
										v-model="props.item.TypeArea"
									/>
								</v-col>

                                <v-col cols="3" md="6" lg="3">
								<v-checkbox v-model="props.item.TcgRequired" label="Obligatorio"></v-checkbox>	
								</v-col>
							</v-row>
                            </v-container>
							
						</template>
                        <template v-slot:TcgRequired={row}>
                    <v-chip
                        :color="row.TcgRequired == 1 ? 'success': 'error'"
                        x-small
                    >
                        {{row.TcgRequired == 1 ? 'Si': 'No'}}
                    </v-chip>
                </template>
					</s-crud>
				</v-col>
			</v-row>
		</div>
	</v-container>
</template>
<script>
	//Service
	import _sTurnConfigService from "../../../services/FreshProduction/PrfTurnConfigService";

	export default {
		components: {},

		data: () => ({
			filter: {TypeShip: 1},
			config: {
				model: {
					TcgID: "ID",
                    TcgRequired: "",
				},
				service: _sTurnConfigService,
				headers: [
					{ text: "Id", value: "TcgID", sortable: false, width: "100", align: "center"},
					{ text: "Nave", value: "TypeShipName", sortable: false},
					{ text: "Area", value: "TypeAreaName", sortable: false },
					{
						text: "Area",
						value: "TcgRequired",
						width: 200, sortable: false, align: "center"
					}
				]
			},
			dgr: 40,
			def: 1281,
		}),

		methods: {

			inputShip(item){
				
					if(item == 1){
						this.def = 1281
						this.dgr = 40
					}

					if(item == 2){
						this.def = 1348
						this.dgr=41
						
					}
	
			},

            save(val){
                if(val.TypeArea == null){
                    this.$fun.alert("Seleccione area", "warning")
                    return;
                }

                if(val.TcgRequired){
                    val.TcgRequired = 1
                }else{
                    val.TcgRequired = 0
                }

                val.SecStatus = 1
                val.UsrCreateID = this.$fun.getUserID()

                console.log("guardar", val);
                val.save()
            }
        }
	};
</script>
